import React, { useState } from 'react';
import { Box, Tabs, Tab as MuiTab } from '@mui/material';

interface TabPanelProps {
	index: number;
	value: number;
	children?: React.ReactNode;
}

interface TabProps {
	label: string;
	children?: React.ReactNode;
}

interface TabWrapperProps {
	children: React.ReactElement<TabProps>[];
}

const TabPanel: React.FunctionComponent<TabPanelProps> = (props) => {
	const { children, value, index } = props;
	return (
		<div id={`tabpanel-${index}`} role={'tabpanel'} hidden={value !== index}>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
}

export const Tab: React.FunctionComponent<TabProps> = (props) => <>{props.children}</>;
export const TabWrapper: React.FunctionComponent<TabWrapperProps> = (props) => {
	const [tab, setTab] = useState(0);
	return (
		<>
			<Tabs value={tab} onChange={(_, value) => setTab(value)}>
				{props.children.map((child) => (
					<MuiTab sx={{ textTransform: 'none' }} label={child.props.label} />
				))}
			</Tabs>
			{props.children.map((child, i) => (
				<TabPanel value={tab} index={i} key={i}>{child}</TabPanel>
			))}
		</>
	);
}

export default TabWrapper;
