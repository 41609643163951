import { experimental_extendTheme as extendTheme } from "@mui/material";
import type {} from '@mui/material/themeCssVarsAugmentation';

declare module '@mui/material' {
	interface ColorSystemOptions {}

	interface PaletteOptions {}
	
	interface TypeBackground {}

	interface CommonColors {
		button: string;
	}
}

export const ColorScheme = extendTheme({
	colorSchemes: {
		light: {
			palette: {
				mode: 'light',
				divider: 'rgb(240, 240, 240)',
				background: {
					default: '#FAFAFB',
					paper: '#FFFFFF',
				},
				text: {
					primary: 'rgba(0, 0, 0, 0.87)',
				},
				common: {
					button: 'rgb(38, 38, 38)',
				},
			},
		},
		dark: {
			palette: {
				mode: 'dark',
				divider: 'rgba(255, 255, 255, 0.05)',
				background: {
					default: '#121212',
					paper: '#1E1E1E',
				},
				text: {
					primary: 'rgba(255, 255, 255, 0.87)',
				},
				common: {
					button: 'rgba(255, 255, 255, 0.87)',
				},
			},
		},
	},
});

export default ColorScheme;
