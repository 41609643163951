import React from 'react';
import { Accordion as MuiAccordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface IProps {
    title: string;
    defaultExpanded?: boolean;
    children: React.ReactNode;
}

const Accordion: React.FunctionComponent<IProps> = ({ title, children, defaultExpanded = false }) => (
    <MuiAccordion sx={{ backgroundColor: 'var(--mui-palette-background-default)' }} defaultExpanded={defaultExpanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>{title}</AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
);

export default Accordion;
