import React, { useState } from 'react';
import { Box, Stack, Paper, Typography, Link, TextField, Button, Grid, InputAdornment } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { TabWrapper, Tab } from '../components/Tabs';
import Accordion from '../components/Accordion';

import * as openpgp from 'openpgp';

const ContentWrapperStyle = {
	display: 'flex',
	width: '100%',
	justifyContent: 'center',
	alignItems: 'center',
	minHeight: '100vh'
}

const TabContainerStyle = {
	marginRight: 'auto',
	marginLeft: 'auto',
	paddingLeft: '15px',
	paddingRight: '15px',

	'@media(min-width: 768px)': { width: '750px' },
	'@media(min-width: 992px)': { width: '970px' },
	'@media(min-width: 1200px)': { width: '1170px' },
}

const WrapperStackStyle = {
	display: 'flex',
	flexDirection: 'column',
	gap: '32px',
}

const WrapperTitleTextStyle = {
	margin: '0px',
	fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	fontWeight: '500',
	fontSize: '2rem',
	lineHeight: '1.2',
}

const GenerateKeys: React.FunctionComponent = () => <>Key generation will be available soon. All other tools are available now.</>;

const SignMessage: React.FunctionComponent = () => {
	const [privateKey, setPrivateKey] = useState('');
	const [message, setMessage] = useState('');
	const [passphrase, setPassphrase] = useState('');
	const [signature, setSignature] = useState('');

	const handleSign = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			const privateKeyObj = await openpgp.decryptKey({
				privateKey: await openpgp.readPrivateKey({ armoredKey: privateKey }),
				passphrase
			});

			const messageObj = await openpgp.createMessage({ text: message });
			const signature = await openpgp.sign({
				message: messageObj,
				signingKeys: privateKeyObj
			});

			setSignature(signature);
		} catch(err) {
			console.error('Signing failed:', err);
			setSignature('Signing failed - check your private key and passphrase');
		}
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} component={'form'} onSubmit={handleSign}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<Stack sx={{ width: '100%', gap: 1 }}>
						<TextField rows={5} label={'Private Key'} onChange={(e) => setPrivateKey(e.target.value)} value={privateKey} fullWidth multiline required />
						<TextField label={'Passphrase'} onChange={(e) => setPassphrase(e.target.value)} value={passphrase} InputProps={{startAdornment: <InputAdornment position={'start'}><LockIcon /></InputAdornment>}} />
					</Stack>
				</Grid>
				<Grid item xs={12} md={6}>
					<Stack sx={{ width: '100%', height: '100%', gap: 1 }}>
						<TextField rows={6} label={'Message'} onChange={(e) => setMessage(e.target.value)} value={message} fullWidth multiline required />
						<Button sx={{ alignSelf: 'flex-end' }} variant={'contained'} type={'submit'}>Sign Message</Button>
					</Stack>
				</Grid>
			</Grid>
			{signature && (
				<TextField
					label={'Generated Signature'}
					value={signature}
					fullWidth
					multiline
					rows={5}
					InputProps={{
						readOnly: true,
					}}
				/>
			)}
		</Box>
	);
};

const VerifyMessage: React.FunctionComponent = () => {
	const [publicKey, setPublicKey] = useState('');
	const [message, setMessage] = useState('');
	const [signature, setSignature] = useState('');
	const [verificationResult, setVerificationResult] = useState<string>('');

	const handleVerify = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			const publicKeyObj = await openpgp.readKey({ armoredKey: publicKey });
			const messageObj = await openpgp.createMessage({ text: message });
			const signatureObj = await openpgp.readSignature({ armoredSignature: signature });

			const verificationResult = await openpgp.verify({
				message: messageObj,
				signature: signatureObj,
				verificationKeys: publicKeyObj
			});

			const { verified } = verificationResult.signatures[0];
			await verified;
			setVerificationResult('Message signature is valid');
		} catch(err) {
			console.error('Verification failed:', err);
			setVerificationResult('Invalid signature');
		}
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} component={'form'} onSubmit={handleVerify}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<TextField rows={5} label={'Public Key'} onChange={(e) => setPublicKey(e.target.value)} value={publicKey} fullWidth multiline required />
				</Grid>
				<Grid item xs={12} md={6}>
					<TextField rows={5} label={'Message'} onChange={(e) => setMessage(e.target.value)} value={message} fullWidth multiline required />
				</Grid>
				<Grid item xs={12}>
					<TextField rows={5} label={'Signature'} onChange={(e) => setSignature(e.target.value)} value={signature} fullWidth multiline required />
				</Grid>
			</Grid>
			<Button sx={{ alignSelf: 'flex-end' }} variant={'contained'} type={'submit'}>Verify Signature</Button>
			{verificationResult && (<Typography color={verificationResult.includes('valid') ? 'success.main' : 'error.main'}>{verificationResult}</Typography>)}
		</Box>
	);
};

const EncryptMessage: React.FunctionComponent = () => {
	const [publicKey, setPublicKey] = useState('');
	const [privateKey, setPrivateKey] = useState('');
	const [passphrase, setPassphrase] = useState('');
	const [rawMessage, setRawMessage] = useState('');
	const [encryptedMessage, setEncryptedMessage] = useState('');

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			const message = await openpgp.createMessage({ text: rawMessage });
			const publicKeyObj = await openpgp.readKey({ armoredKey: publicKey });
			
			let signingKey;
			if(privateKey && passphrase) {
				const privateKeyObj = await openpgp.readPrivateKey({ armoredKey: privateKey });
				signingKey = await openpgp.decryptKey({ privateKey: privateKeyObj, passphrase });
			}

			const encrypted = await openpgp.encrypt({
				message,
				encryptionKeys: publicKeyObj,
				signingKeys: signingKey
			});

			setEncryptedMessage(encrypted);
		} catch(err) {
			console.error('Encryption failed:', err);
		}
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} component={'form'} onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={10} md={5}>
					<Stack sx={{ width: '100%', gap: 1 }}>
						<TextField rows={5} label={'Public Key'} onChange={(e) => setPublicKey(e.target.value)} value={publicKey} multiline required />
						<TextField rows={5} label={'Private Key (optional)'} onChange={(e) => setPrivateKey(e.target.value)} value={privateKey} multiline />
						<TextField label={'Passphrase'} onChange={(e) => setPassphrase(e.target.value)} value={passphrase} InputProps={{startAdornment: <InputAdornment position={'start'}><LockIcon /></InputAdornment>}} />
					</Stack>
				</Grid>
				<Grid item xs={14} md={7}>
					<Stack sx={{ width: '100%', height: '100%', gap: 1 }}>
						<TextField rows={12} sx={{ height: '100%' }} label={'Raw Message'} onChange={(e) => setRawMessage(e.target.value)} value={rawMessage} multiline />
						<Button sx={{ alignSelf: 'flex-end' }} variant={'contained'} type={'submit'}>Encrypt</Button>
					</Stack>
				</Grid>
			</Grid>
			{encryptedMessage && <TextField rows={6} sx={{ height: '100%', gap: 1 }} label={'Encrypted Message'} value={encryptedMessage} InputProps={{ readOnly: true }} multiline />}
		</Box>
	);
};

const DecryptMessage: React.FunctionComponent = () => {
	const [publicKey, setPublicKey] = useState('');
	const [privateKey, setPrivateKey] = useState('');
	const [passphrase, setPassphrase] = useState('');
	const [encryptedMessage, setEncryptedMessage] = useState('');
	const [decryptedMessage, setDecryptedMessage] = useState('');

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			const privateKeyObj = await openpgp.readPrivateKey({ armoredKey: privateKey });
			const decryptedPrivateKey = await openpgp.decryptKey({ privateKey: privateKeyObj, passphrase });
			const message = await openpgp.readMessage({ armoredMessage: encryptedMessage });

			let verificationKeys: openpgp.Key[] = [];
			if(publicKey) {
				const publicKeyObj = await openpgp.readKey({ armoredKey: publicKey });
				verificationKeys = [publicKeyObj];
			}

			const { data: decrypted } = await openpgp.decrypt({ message, decryptionKeys: decryptedPrivateKey, verificationKeys });
			setDecryptedMessage(decrypted as string);
		} catch(err) {
			console.error('Decryption failed:', err);
		}
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} component={'form'} onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={10} md={5}>
					<Stack sx={{ width: '100%', gap: 1 }}>
						<TextField rows={5} label={'Public Key (For Verification)'} onChange={(e) => setPublicKey(e.target.value)} value={publicKey} multiline />
						<TextField rows={5} label={'Private Key'} onChange={(e) => setPrivateKey(e.target.value)} value={privateKey} multiline required />
						<TextField label={'Passphrase'} onChange={(e) => setPassphrase(e.target.value)} value={passphrase} InputProps={{ startAdornment: <InputAdornment position={'start'}><LockIcon /></InputAdornment> }} />
					</Stack>
				</Grid>
				<Grid item xs={14} md={7}>
					<Stack sx={{ width: '100%', height: '100%', gap: 1 }}>
						<TextField sx={{ height: '100%' }} label={'Encrypted Message'} rows={12} value={encryptedMessage} onChange={(e) => setEncryptedMessage(e.target.value)} multiline required />
						<Button sx={{ alignSelf: 'flex-end' }} variant={'contained'} type={'submit'}>Decrypt</Button>
					</Stack>
				</Grid>
			</Grid>
			{decryptedMessage && <TextField sx={{ height: '100%' }} label={'Decrypted Message'} rows={6} value={decryptedMessage} InputProps={{ readOnly: true }} multiline />}
		</Box>
	);
};

const FAQ: React.FunctionComponent = () => (
	<>
		<Accordion title={'Is this website safe? How do I know my keys are secure?'} defaultExpanded>
			<Typography variant={'body1'}>
				This website is safe to use. It is a client-side browser tool for PGP Encryption.
				This means that your keys are never sent to any server and are only seen by you on your device.
				No servers have access to your keys or any of your encrypted data and nothing will ever be sent elsewhere.
			</Typography>
		</Accordion>
		<Accordion title={'What is PGP?'}>
			<Typography variant={'body1'}>
				Pretty Good Privacy (PGP) is a data encryption and decryption computer program that provides cryptographic privacy and authentication for data communication.
				PGP is often used for signing, encrypting, and decrypting texts, e-mails, files, directories, and whole disk partitions and to increase the security of e-mail communications.
				It was created by Phil Zimmermann in 1991. PGP and similar software follow the OpenPGP standard (RFC 4880) for encrypting and decrypting data.
			</Typography>
			<Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
				<Typography variant={'body1'}>Source:</Typography>
				<Link sx={{ textDecoration: 'none' }} href={'https://en.wikipedia.org/wiki/Pretty_Good_Privacy'} target={'_blank'} rel={'noopener noreferrer'}>Wikipedia</Link>
			</Box>
		</Accordion>
		<Accordion title={'Why is my browser lagging when using this website?'}>
			<Typography variant={'body1'}>
				Crypography in general can be resource intensive. Devices with limited memory or processing power may experience lag as a result of the encryption process.
			</Typography>
		</Accordion>
	</>
);

const XMRAddress = "89zK2kx3iF1Gdd8VFFNUX9aJT8hxLA3EaewBgmnb4F5ceRd7P5f3Nvbf8VHBmhuzzoMXsugSeGRHVaZ4gB4s9EzS2GVq91y";
const About: React.FunctionComponent = () => (
	<Box>
		<Typography variant={'body1'}>A simple and secure client-side browser tool for PGP Encryption.</Typography>

		<Typography sx={{ mt: 3 }} variant={'body2'}>All donations are greatly appreciated and will be used for hosting costs and maintaining the project.</Typography>
		<Typography sx={{ mt: 0.5 }} variant={'body2'}>XMR: {XMRAddress}</Typography>
	</Box>
);

export const HomePath = "/";
export const Home: React.FunctionComponent = () => (
	<Box sx={ContentWrapperStyle}>
		<Stack sx={WrapperStackStyle}>
			<Typography sx={WrapperTitleTextStyle} variant={'h4'}>PGP Tool</Typography>
			<Paper sx={TabContainerStyle}>
				<Box sx={{ padding: '0 0 8px 0' }}>
					<TabWrapper>
						<Tab label={'Generate Keys'}><GenerateKeys /></Tab>
						<Tab label={'Sign'}><SignMessage /></Tab>
						<Tab label={'Verify'}><VerifyMessage /></Tab>
						<Tab label={'Encrypt (+Sign)'}><EncryptMessage /></Tab>
						<Tab label={'Decrypt (+Verify)'}><DecryptMessage /></Tab>
						<Tab label={'FAQ'}><FAQ /></Tab>
						<Tab label={'About'}><About /></Tab>
					</TabWrapper>
				</Box>
			</Paper>
		</Stack>
	</Box>
);

export default Home;
